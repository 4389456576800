import {
  Box,
  Container,
  MenuItem,
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../long-logo.svg";

const logoStyle = {
  width: "140px",
  height: "25px",
  cursor: "pointer",
};

export function AppBar() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    { label: "Home", scrollTo: "home", path: "/" },
    { label: "About us", scrollTo: "aboutus", path: "/" },
    { label: "Benefits", scrollTo: "benefits", path: "/" },
    { label: "Pricing", scrollTo: "pricing", path: "/" },
    { label: "Journey", scrollTo: "article", path: "/journey" },
    {
      label: "Bachelorette party",
      scrollTo: "bachelorette-party",
      path: "/bachelorette-party",
    },
  ];

  return (
    <MuiAppBar
      position="fixed"
      color="transparent"
      sx={{
        width: "100%",
        bgcolor: "white",
        backdropFilter: "blur(24px)",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Container maxWidth="lg">
        <Toolbar
          variant="regular"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* Logo */}
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={logo}
              style={logoStyle}
              alt="logo of hoppenhagen"
              onClick={() => {
                navigate("/"); // Ensure you're on the homepage
                setTimeout(() => {
                  const section = document.getElementById("home");
                  if (section) {
                    section.scrollIntoView({ behavior: "smooth" });
                  }
                }, 100); // Timeout ensures navigation happens first
              }}
            />
          </Box>

          {/* Desktop Menu */}
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {menuItems.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  if (item.scrollTo) {
                    navigate(item.path, { state: { scrollTo: item.scrollTo } });
                  } else {
                    navigate(item.path);
                  }
                }}
              >
                <Typography variant="body2" color="text.primary">
                  {item.label}
                </Typography>
              </MenuItem>
            ))}
          </Box>

          {/* Mobile Menu */}
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={handleMenuClose}
              PaperProps={{
                style: {
                  width: "200px",
                },
              }}
            >
              {menuItems.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    handleMenuClose();
                    if (item.scrollTo) {
                      navigate(item.path, {
                        state: { scrollTo: item.scrollTo },
                      });
                    } else {
                      navigate(item.path);
                    }
                  }}
                >
                  <Typography variant="body2">{item.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </MuiAppBar>
  );
}
