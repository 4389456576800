import { Typography, Box, Divider } from "@mui/material";
import Carousel from "react-material-ui-carousel";

export function Article() {
  const carouselItems = [
    {
      imgSrc: "./K3.jpg",
      alt: "Kangoo Jumps 1",
    },
    {
      imgSrc: "./K2.jpg",
      alt: "Kangoo Jumps 2",
    },
    {
      imgSrc: "./K1.png",
      alt: "Kangoo Jumps 3",
    },
  ];
  return (
    <>
      <Box
        id="article"
        sx={{
          mt: 12,
          pt: { xs: 12, sm: 24 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 5 },
          textAlign: "center",
          bgcolor: "#f9f9f9",
          p: { xs: 3, sm: 6 },
          width: "100%",
        }}
      >
        <Typography
          variant="h3"
          component="h1"
          sx={{
            fontWeight: "bold",
            color: "rgba(90, 47, 158, 1)",
            textAlign: "center",
          }}
        >
          How it all began: The start of something fun
        </Typography>

        <Typography
          variant="subtitle1"
          sx={{
            fontStyle: "italic",
            color: "#757575",
          }}
        >
          By Claudia-Roxana Budimir | October 2024
        </Typography>

        <Divider sx={{ width: "80%", my: { xs: 2, sm: 4 } }} />

        <Box sx={{ textAlign: "left", maxWidth: "800px" }}>
          <Typography
            variant="body1"
            paragraph
            sx={{ lineHeight: 1.8, color: "#424242" }}
          >
            It’s more of a vibe, but I did my best to wrap it up in a story for
            you!
          </Typography>

          <Typography variant="body1" paragraph sx={{ color: "#424242" }}>
            It all started in 2018 when I joined Aoleu Fitness studio, which
            offered Kangoo Jumps classes. Before that, I had been introduced to
            Kangoo Jumps by my sister’s friend Cornelia, as we had attended a
            few classes with an independent instructor together. Eventually, the
            three of us immersed ourselves in the thrilling world of Kangoo
            Jumps at Aoleu Fitness. From the very first session, I was
            captivated not only by the workout itself but also by the amazing
            sense of community it created. It felt like more than just a fitness
            class; it was like being part of a warm, supportive family. That
            feeling of connection was what hooked me and kept me coming back.
          </Typography>

          <Typography variant="body1" paragraph sx={{ color: "#424242" }}>
            One of the most special connections I made in the Kangoo Jumps class
            was with Dana, who soon became one of my closest friends. Despite
            being 20 years older than me, her positivity, energy, and enthusiasm
            were contagious. She represented everything I admired and wanted in
            my life: people who were fit, fun, and full of energy. A true FFF
            family: Fun, Fit, and Full of Life. Dana, and others like her, made
            Kangoo Jumps feel less like a workout and more like a hangout with
            family. A family of people who inspire and lift each other up.
          </Typography>

          <Typography variant="body1" paragraph sx={{ color: "#424242" }}>
            For three years, Kangoo Jumps was a huge part of my life. I looked
            forward to every class, feeling like I belonged in this vibrant,
            supportive community. But in 2021, when I moved to Denmark for my
            studies, I had to take a break from Kangoo. There was no Kangoo
            Jumps studio in Copenhagen. Though I was focused on my new life, I
            still craved that sense of camaraderie and movement I had left
            behind. Whenever I returned home during breaks, I made sure to
            squeeze in a few classes, reconnecting with the people and the
            energy that had always brought me so much joy.
          </Typography>

          <Typography variant="body1" paragraph sx={{ color: "#424242" }}>
            Then came a turning point during my 2023 Christmas break. I went to
            a class, and after chatting with the instructor, I opened up about
            how much I missed being part of that Kangoo Jumps community. She
            listened, then casually suggested something that completely shifted
            my perspective: “Why don’t you get your instructor’s license? If
            there’s no Kangoo Jumps studio in Copenhagen, you could start one
            yourself!” She was right. If I couldn’t find what I was missing in
            Copenhagen, why not create it myself? To be honest, the idea had
            popped into my head before, but hearing it from someone else made me
            think, “Okay, universe, I get it, time to jump into action!”
          </Typography>

          <Typography variant="body1" paragraph sx={{ color: "#424242" }}>
            That was the turning point. By February 2024, I was fully committed
            to making it happen and was already enrolled in the instructor
            training course, determined to bring Kangoo Jumps to Copenhagen and
            share that same sense of belonging and fun with others. In May, I
            taught my very first class. It felt empowering, standing at the
            front of the room, knowing that I was about to create a new
            community, just like the one that had shaped me years ago. Even if
            my audience consisted of only two girls, one of whom was my friend
            Madalina, it was a start, my start! You’ve got to start somewhere,
            right? I looked at it as if it was an exclusive VIP launch party!
            That’s when Hoppenhagen was born, at a VIP party. The name captures
            exactly what I want this to be: a fun, energetic, community-oriented
            fitness club.
          </Typography>

          <Typography variant="body1" paragraph sx={{ color: "#424242" }}>
            Hoppenhagen is more than just fitness; it’s about creating a
            supportive, joyful community, just like the one that welcomed me
            back in 2018. Now, I’m not just participating; I’m teaching, sharing
            my passion and building a new Fun, Fit, and Full of Life family here
            in Denmark.
          </Typography>

          <Typography
            variant="h6"
            sx={{
              mt: { xs: 3, sm: 5 },
              fontWeight: "bold",
              color: "rgba(90, 47, 158, 1)",
              textAlign: "center",
            }}
          >
            To conclude, I want to welcome you all to Hoppenhagen, where fun
            meets fitness!
          </Typography>
        </Box>
      </Box>
      <Carousel
        sx={{ width: "100%", bgcolor: "#f9f9f9", pb: 3 }}
        autoPlay={true}
        interval={3000} // Change image every 3 seconds
        animation="slide"
        indicators={true} // Display the indicators
        navButtonsAlwaysVisible={true} // Show navigation arrows
      >
        {carouselItems.map((item, index) => (
          <div key={index}>
            <img
              src={item.imgSrc}
              alt={item.alt}
              style={{
                width: "50%",
                // height: 350,
                // borderRadius: "8px",
                // boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              }}
            />
          </div>
        ))}
      </Carousel>
    </>
  );
}
