import React, { useEffect } from "react";
import { Typography, Box, Divider } from "@mui/material";
// import Carousel from "react-material-ui-carousel";

// import { Helmet } from "react-helmet-async";
import { AppBar, Footer, Page } from "../../components";

export function BacheloretteParty() {
  // Scroll to the top of the page when this component loads
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []); // TODO Claudia: fix trick

  // const carouselItems = [
  //   {
  //     imgSrc: "./Kangoo1.jpg",
  //     alt: "Bachelorette Party Fun 1",
  //   },
  //   {
  //     imgSrc: "./Kangoo2.jpg",
  //     alt: "Bachelorette Party Fun 2",
  //   },
  //   {
  //     imgSrc: "./Kangoo3.jpg",
  //     alt: "Bachelorette Party Fun 3",
  //   },
  // ];

  return (
    <Page>
      {/* <Helmet>
        <title>Journey</title>
      </Helmet> */}
      <AppBar></AppBar>

      <Box
        sx={{
          background: "linear-gradient(to right, #5a2f9e, #59fc3c)",
          color: "#fff",
          textAlign: "center",
        }}
      >
        <>
          <Box
            id="bachelorette-party"
            sx={{
              mt: 12,
              pt: { xs: 12, sm: 24 },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: { xs: 3, sm: 5 },
              textAlign: "center",
              bgcolor: "#f9f9f9",
              p: { xs: 3, sm: 6 },
              width: "100%",
            }}
          >
            <Typography
              variant="h3"
              component="h1"
              sx={{
                fontWeight: "bold",
                color: "rgba(90, 47, 158, 1)",
                textAlign: "center",
              }}
            >
              Hoppenhagen Presents: Unforgettable Kangoo Jumps Bachelorette
              Parties
            </Typography>

            <Typography
              variant="subtitle1"
              sx={{
                fontStyle: "italic",
                color: "#757575",
              }}
            >
              It's a celebration!
            </Typography>

            <Divider sx={{ width: "80%", my: { xs: 2, sm: 4 } }} />

            <Box sx={{ textAlign: "left", maxWidth: "800px" }}>
              <Typography
                variant="body1"
                paragraph
                sx={{ lineHeight: 1.8, color: "#424242" }}
              >
                Looking for a unique and high-energy way to celebrate your
                bachelorette party in Copenhagen? Hoppenhagen brings you the
                ultimate Kangoo Jumps Bachelorette Party – a perfect mix of
                fitness, fun, and laughter for you and your best friends!
              </Typography>

              <Typography variant="body1" paragraph sx={{ color: "#424242" }}>
                Whether you’re planning for a bride who loves to stay active or
                simply want a one-of-a-kind bonding experience, our Kangoo Jumps
                classes are the perfect choice for a memorable bachelorette
                party.
              </Typography>

              <Typography
                variant="h5"
                sx={{ mt: 4, fontWeight: "bold", color: "#5a2f9e" }}
              >
                What is a Kangoo Jumps Bachelorette Party?
              </Typography>
              <Typography variant="body1" paragraph sx={{ color: "#424242" }}>
                Kangoo Jumps is a unique fitness workout using specially
                designed rebound boots, giving you the feeling of bouncing on a
                trampoline. This low-impact activity is fun, beginner-friendly,
                and a great way to bring everyone together, regardless of
                fitness level.
              </Typography>

              <Typography
                variant="h6"
                sx={{ mt: 4, fontWeight: "bold", color: "#5a2f9e" }}
              >
                What’s Included in Your Kangoo Jumps Bachelorette Party?
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ color: "#424242", pl: { xs: 0, sm: 2 } }}
              >
                - 1-Hour Private Class with an expert instructor. <br />
                - Kangoo Jumps Boots provided for all participants. <br />
                - Photos & Videos capturing action-packed memories. <br />
              </Typography>

              <Typography
                variant="h6"
                sx={{ mt: 4, fontWeight: "bold", color: "#5a2f9e" }}
              >
                Important Details
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ color: "#424242", pl: { xs: 0, sm: 2 } }}
              >
                Group Size: Up to 15 people (smaller groups are welcome!) <br />
                Price: 150 DKK per person <br />
                Duration: 1-hour private session <br />
                Booking: Secure your spot at least one month in advance.
              </Typography>

              <Typography
                variant="h5"
                sx={{ mt: 4, fontWeight: "bold", color: "#5a2f9e" }}
              >
                Why Choose Hoppenhagen?
              </Typography>
              <Typography variant="body1" paragraph sx={{ color: "#424242" }}>
                Celebrate your bachelorette party in a way that’s as unique and
                unforgettable as the bride herself! A Kangoo Jumps party is the
                perfect blend of laughter, movement, and togetherness. It’s not
                just a workout, it’s a shared adventure that gets everyone
                involved, no matter their fitness level. With upbeat music,
                bouncing energy, and plenty of photo-worthy moments, this
                experience guarantees laughter, bonding, and memories that
                you’ll cherish long after the big day. Plus, it’s a refreshing
                alternative to traditional celebrations, ensuring your party
                stands out as one-of-a-kind!
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  mt: { xs: 3, sm: 5 },
                  fontWeight: "bold",
                  color: "rgba(90, 47, 158, 1)",
                  textAlign: "center",
                }}
              >
                Book Your Kangoo Jumps Bachelorette Party Today! Send us an
                email at hoppenhagen@gmail.com or contact us at +45 55203073 if
                you have an inquiry and let's make the party happen!
              </Typography>
            </Box>
          </Box>
          {/* <Carousel
            sx={{ width: "100%", bgcolor: "#f9f9f9", pb: 3 }}
            autoPlay={true}
            interval={3000}
            animation="slide"
            indicators={true}
            navButtonsAlwaysVisible={true}
          >
            {carouselItems.map((item, index) => (
              <div key={index}>
                <img
                  src={item.imgSrc}
                  alt={item.alt}
                  style={{
                    width: "50%",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  }}
                />
              </div>
            ))}
          </Carousel> */}
        </>
        <Footer />
      </Box>
    </Page>
  );
}
