import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

const tiers = [
  {
    title: "Trial class",
    price: 80,
    description: ["One time class", "Valid one month from purchase"],
    buttonText: "Book trial class",
    buttonVariant: "outlined",
    buttonUrl: "https://book.stripe.com/28o3fFfSQ5Vu03S8ww",
  },
  {
    title: "Once a week pass",
    price: 400,
    description: [
      "Access to one weekly class (Wednesday or Sunday)",
      "Hassle-free automatic renewal every month",
    ],
    buttonText: "Make once a week pass",
    buttonVariant: "outlined",
    buttonUrl: "https://buy.stripe.com/aEUbMb7mkabK9Es002",
  },
  {
    title: "Full pass",
    price: 600,
    description: [
      "Unlimited access to all weekly classes",
      "Includes free participation to special events",
      "Hassle-free automatic renewal every month",
    ],
    subheader: "Best Value",
    buttonText: "Make full pass",
    buttonVariant: "contained",
    buttonUrl: "https://buy.stripe.com/fZeeYneOMabKaIw9AD",
  },
];

export default function Pricing() {
  return (
    <Container
      id="pricing"
      maxWidth="lg"
      sx={{
        pt: { xs: 4, sm: 12 },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 1, sm: 2 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography component="h2" variant="h4" color="white">
          Pricing
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        alignItems="stretch"
        justifyContent="center"
        sx={{
          maxWidth: 1200,
        }}
      >
        {tiers.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={6}
            md={4}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
                maxWidth: 500,
                height: "100%",
                p: 2,
                backgroundColor: "white",
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.subheader && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label={tier.subheader}
                      size="small"
                      sx={{
                        backgroundColor: "#59fc3c",
                        color: "black",
                        "& .MuiChip-icon": {
                          color: "black",
                        },
                      }}
                    />
                  )}
                </Box>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <Typography component="h3" variant="h3">
                    {tier.price} DKK
                  </Typography>
                  {tier.title !== "Trial class" ? (
                    <Typography component="h3" variant="h6">
                      &nbsp; per month
                    </Typography>
                  ) : (
                    <Typography component="h3" variant="h6">
                      &nbsp;
                    </Typography>
                  )}
                </Box>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: "grey.500",
                  }}
                />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: "flex",
                      gap: 1.5,
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color: "#59fc3c",
                      }}
                    />
                    <Typography variant="subtitle2">{line}</Typography>
                  </Box>
                ))}
              </CardContent>

              <CardActions>
                <Button
                  href={tier.buttonUrl}
                  fullWidth
                  variant={tier.buttonVariant as "outlined" | "contained"}
                  sx={{
                    backgroundColor:
                      tier.buttonVariant === "contained"
                        ? "#5a2f9e"
                        : "transparent",
                    color:
                      tier.buttonVariant === "contained" ? "white" : "#5a2f9e",
                    borderColor: "#5a2f9e",
                    "&:hover": {
                      backgroundColor:
                        tier.buttonVariant === "contained"
                          ? "#4a237e"
                          : "rgba(90, 47, 158, 0.1)",
                    },
                  }}
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
