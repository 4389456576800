// import { Helmet } from "react-helmet-async";
import { useEffect } from "react";
import { AppBar, Article, Footer, Page } from "../../components";
import { Box } from "@mui/material";

export function Journey() {
  // Scroll to the top of the page when this component loads
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []); // TODO Claudia: fix trick

  return (
    <Page>
      {/* <Helmet>
        <title>Journey</title>
      </Helmet> */}
      <AppBar></AppBar>

      <Box
        sx={{
          background: "linear-gradient(to right, #5a2f9e, #59fc3c)",
          color: "#fff",
          textAlign: "center",
        }}
      >
        <Article />
        <Footer />
      </Box>
    </Page>
  );
}
