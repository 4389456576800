import { CssBaseline } from "@mui/material";
import { HelmetProvider } from "react-helmet-async";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { BacheloretteParty, Home, Journey } from "./pages";
import "./App.css";

function App(): JSX.Element {
  return (
    <HelmetProvider>
      <CssBaseline />
      {/* <Helmet>
        <title>Hoppehagen</title>
        <meta
          name="A fitness club with Kangoo Jumps classes"
          content="Booking intense fat burning cardio workout in Copenhagen, Denmark"
        />
      </Helmet> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/journey" element={<Journey />} />
          <Route path="/bachelorette-party" element={<BacheloretteParty />} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
