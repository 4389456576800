import { Stack } from "@mui/material";
import { PropsWithChildren } from "react";

export function Page({ children }: PropsWithChildren<unknown>): JSX.Element {
  return (
    <Stack height="100%" maxHeight="100%" overflow="hidden">
      {children}
    </Stack>
  );
}
